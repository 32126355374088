import Autoplay from "embla-carousel-autoplay";
import type { ComponentPropsWithoutRef } from "react";
import type { Contact } from "~types/api/contact.types";
import type { Product } from "~types/api/product.types";
import type { SessionData } from "~types/app/session.types";

import { ProductCard } from "~/components/ui/cards/product-card";
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "~/components/ui/carousels/carousel";
import { cn } from "~/utils/classnames";

interface ProductCardCarouselProps extends ComponentPropsWithoutRef<"div"> {
  products: Product[];
  comparator: SessionData["comparator"] | null;
  contact: Contact | null;
}

export function ProductCardCarousel({
  products,
  contact,
  comparator,
  className,
}: ProductCardCarouselProps) {
  return (
    <Carousel
      opts={{
        align: "center",
        breakpoints: {
          "(min-width: 640px)": { align: "start" },
        },
      }}
      plugins={[
        Autoplay({
          delay: 4000,
          stopOnInteraction: false,
          stopOnMouseEnter: true,
        }),
      ]}
      className={className}
    >
      <CarouselPrevious className="top-44 hidden lg:flex" />

      {/* Children can overflow without break the carousel */}
      <CarouselContent className="-mx-1 py-2" containerClassName="pointer-events-none pb-64 -mb-64">
        {products.map((product) => (
          <CarouselItem
            key={product.id}
            className={cn("pointer-events-auto px-2 *:h-full md:basis-1/2 lg:basis-1/3")}
          >
            <ProductCard
              key={"products-caroussel-" + product.id}
              product={product}
              comparator={comparator || null}
              contact={contact || null}
              className="h-full"
            />
          </CarouselItem>
        ))}
      </CarouselContent>

      <CarouselNext className="top-44 hidden lg:flex" />

      {/* This div allow click through the pb-64 padding (for absolute stock popover) */}
      <div className="flex" />

      <CarouselDots />
    </Carousel>
  );
}
